import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom'; // Importação do Link
import Api from '../../Api';

interface Dados {
    ano?: string;
    codigo1?: string;
    codigo2?: string;
    contratado?: string;
    razao?: string;
    data_pub?: string;
    objeto?: string;
    valor?: string;
    pz_datainicio?: string;
    pz_datafim?: string;
    arq_data_pub?: string;
    arquivo1?: string;
    arquivo2?: string;
    arquivo3?: string;
    arquivo4?: string;
    arquivo5?: string;
    arquivo6?: string;
    arquivo7?: string;
    arquivo8?: string;
    arquivo9?: string;
    arquivo10?: string;
    texto?: string;
    obs?: string;
}

const ConvenioMunicipalDetalhes: React.FC = () => {
    const [dados, setDados] = useState<Dados | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        const fetchDados = async () => {
            try {
                setIsLoading(true);
                const response = await Api.get<Dados>(`arquivos/${id}`);
                setDados(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching ata:', error);
                setIsLoading(false);
            }
        };

        fetchDados();
    }, [id]);

    return (
        <div className="container mx-auto mt-5 mb-5">
            {isLoading ? (
                <div className="flex justify-center">
                    <div className="loading-spinner"></div>
                </div>
            ) : (
                <div>
                    <h2 className="text-center text-2xl font-bold mb-4">Detalhes do Convênio Municipal</h2>
                    <div className="border border-gray-400 p-4">
                        {dados?.ano && <p><strong>Ano:</strong> {dados.ano}</p>}
                        {dados?.codigo1 && <p><strong>Nº do Contrato:</strong> {dados.codigo1}</p>}
                        {dados?.codigo2 && <p><strong>Concedente:</strong> {dados.codigo2}</p>}
                        {dados?.contratado && <p><strong>Convenente:</strong> {dados.contratado}</p>}
                        {dados?.razao && <p><strong>Razão Social do Convenente:</strong> {dados.razao}</p>}
                        {dados?.data_pub && <p><strong>Data de Publicação:</strong> {dados.data_pub}</p>}
                        {dados?.objeto && <p><strong>Objeto:</strong> {dados.objeto}</p>}
                        {dados?.valor && <p><strong>Valor:</strong> R$ {parseFloat(dados.valor).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</p>}
                        {dados?.pz_datainicio && dados?.pz_datafim && <p><strong>Prazo de vigência do contrato:</strong> {`${dados.pz_datainicio} até ${dados.pz_datafim}`}</p>}
                        {dados?.arq_data_pub && (
                            <p><strong>Arquivo Convênio:</strong> <button
                                className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm"
                                onClick={() => window.open(dados.arq_data_pub, "_blank")}
                            >
                                Visualizar arquivo
                            </button></p>
                        )}

                        {/* Arquivos adicionais */}
                        {dados?.arquivo1 && (
                            <p><strong>Arquivo 1:</strong> <button
                                className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm"
                                onClick={() => window.open(dados.arquivo1, "_blank")}
                            >
                                Visualizar arquivo
                            </button></p>
                        )}
                        {dados?.arquivo2 && (
                            <p><strong>Arquivo 2:</strong> <button
                                className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm"
                                onClick={() => window.open(dados.arquivo2, "_blank")}
                            >
                                Visualizar arquivo
                            </button></p>
                        )}
                        {dados?.arquivo3 && (
                            <p><strong>Arquivo 3:</strong> <button
                                className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm"
                                onClick={() => window.open(dados.arquivo3, "_blank")}
                            >
                                Visualizar arquivo
                            </button></p>
                        )}
                        {dados?.arquivo4 && (
                            <p><strong>Arquivo 4:</strong> <button
                                className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm"
                                onClick={() => window.open(dados.arquivo4, "_blank")}
                            >
                                Visualizar arquivo
                            </button></p>
                        )}
                        {dados?.arquivo5 && (
                            <p><strong>Arquivo 5:</strong> <button
                                className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm"
                                onClick={() => window.open(dados.arquivo5, "_blank")}
                            >
                                Visualizar arquivo
                            </button></p>
                        )}
                        {dados?.arquivo6 && (
                            <p><strong>Arquivo 6:</strong> <button
                                className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm"
                                onClick={() => window.open(dados.arquivo6, "_blank")}
                            >
                                Visualizar arquivo
                            </button></p>
                        )}
                        {dados?.arquivo7 && (
                            <p><strong>Arquivo 7:</strong> <button
                                className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm"
                                onClick={() => window.open(dados.arquivo7, "_blank")}
                            >
                                Visualizar arquivo
                            </button></p>
                        )}
                        {dados?.arquivo8 && (
                            <p><strong>Arquivo 8:</strong> <button
                                className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm"
                                onClick={() => window.open(dados.arquivo8, "_blank")}
                            >
                                Visualizar arquivo
                            </button></p>
                        )}
                        {dados?.arquivo9 && (
                            <p><strong>Arquivo 9:</strong> <button
                                className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm"
                                onClick={() => window.open(dados.arquivo9, "_blank")}
                            >
                                Visualizar arquivo
                            </button></p>
                        )}
                        {dados?.arquivo10 && (
                            <p><strong>Arquivo 10:</strong> <button
                                className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm"
                                onClick={() => window.open(dados.arquivo10, "_blank")}
                            >
                                Visualizar arquivo
                            </button></p>
                        )}

                        {dados?.texto && <p><strong>Descrição:</strong> {dados.texto}</p>}
                        {dados?.obs && <p><strong>Obs:</strong> {dados.obs}</p>}
                    </div>

                    <div className="mt-4">
                        <Link to="/convenio-municipal" className="bg-blue-500 text-white py-2 px-4 rounded">Voltar</Link>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ConvenioMunicipalDetalhes;
