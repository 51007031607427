import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Api from '../../Api';
import Pagination from '../../components/Pagination';
import NavigationButtons from '../../components/NavigationButtons';

interface Ata {
    id: number;
    texto: string;
    ano: string;
}

interface Link {
    url: string | null;
    label: string;
    active: boolean;
}

interface ApiResponse {
    data: Ata[];
    links: Link[];
    total: number;
    last_page: number;
}

const ChamadaPublica: React.FC = () => {
    const [atas, setAtas] = useState<ApiResponse | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();

    const query = new URLSearchParams(location.search);
    const currentPage = parseInt(query.get('page') || '1', 10);

    useEffect(() => {
        const fetchAtas = async () => {
            try {
                setIsLoading(true);
                const params = new URLSearchParams();

                params.append('offset', '0');
                params.append('take', '15');
                params.append('skip', '0');
                params.append('pageSize', '15');
                params.append('page', currentPage.toString());

                const response = await Api.get<ApiResponse>(`chamada-publica/all?${params.toString()}`);
                setAtas(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching atas:', error);
                setIsLoading(false);
            }
        };

        fetchAtas();
    }, [currentPage,]);

    const handlePageChange = (page: number) => {
        navigate(`?page=${page}`);
    };

    return (
        <div className="container mx-auto mt-5 mb-5">
             <NavigationButtons />
            {isLoading ? (
                <div className="flex justify-center">
                    <div className="loading-spinner"></div>
                </div>
            ) : (
                <div className="overflow-x-auto">
                    <h2 className="text-center text-2xl font-bold mb-4">Chamada Pública</h2>
                    <table className="border-collapse border border-gray-400 w-full">
                        <thead className="bg-gray-200">
                            <tr>
                                <th className="border border-gray-400 p-2">Texto</th>
                                <th className="border border-gray-400 p-2">Ano</th>
                                <th className="border border-gray-400 p-2"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {atas?.data.map(ata => (
                                <tr key={ata.id}>
                                    <td className="border border-gray-400 p-2">{ata.texto}</td>
                                    <td className="border border-gray-400 p-2">{ata.ano}</td>
                                    <td className="border border-gray-400 p-2">
                                        <button
                                            className="bg-blue-500 text-white py-2 px-4 rounded"
                                            onClick={() => navigate(`/chamadapublica/detalhes/${ata.id}`)}
                                        >
                                            Abrir
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
            <Pagination
                totalPages={atas?.last_page || 0}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                totalRecords={atas?.total || 0}
            />
        </div>
    );
};

export default ChamadaPublica;
