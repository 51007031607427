import React from 'react';
import NavigationButtons from '../../components/NavigationButtons';

const Convenios = () => {
  return (
    <div className="container mx-auto mt-5 mb-5">
       <NavigationButtons />
        <h2 className="text-center text-2xl font-bold mb-4">Convênios</h2>
    <div className="flex justify-center">
      <div className="w-1/2 p-4">
        <div className="bg-white rounded-lg shadow-md">
          <div className="p-4">
            <div className="text-center mb-4">
              <i className="fas fa-handshake text-2xl"></i>
            </div>
            <h3 className="text-center text-lg font-semibold mb-2">Convênio Governo Estadual</h3>
            <p className="text-center mb-4">
              <a href="http://www.portaldatransparencia.ms.gov.br/Convenios" target="_black" className="btn btn-primary btn-block">Abrir</a>
            </p>
          </div>
        </div>
      </div>
      <div className="w-1/2 p-4">
        <div className="bg-white rounded-lg shadow-md">
          <div className="p-4">
            <div className="text-center mb-4">
              <i className="fas fa-handshake-alt text-2xl"></i>
            </div>
            <h3 className="text-center text-lg font-semibold mb-2">Convênio Governo Federal</h3>
            <p className="text-center mb-4">
              <a href="http://www.portaldatransparencia.gov.br/convenios/ConveniosLista.asp?UF=MS&amp;CodMunicipio=9013&amp;CodOrgao=&amp;TipoConsulta=0&amp;Periodo=" target="_black" className="btn btn-primary btn-block">Abrir</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Convenios;
