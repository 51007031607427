import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom'; // Importação do Link
import Api from '../../Api';

interface Dados {
    nome?: string;
    data?: string;
    arquivo?: string;
    ativo?: boolean;
}

const DiarioAntigoDetalhes: React.FC = () => {
    const [dados, setDados] = useState<Dados | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        const fetchDados = async () => {
            try {
                setIsLoading(true);
                const response = await Api.get<Dados>(`diario-antigo/${id}`);
                setDados(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching ata:', error);
                setIsLoading(false);
            }
        };

        fetchDados();
    }, [id]);

    return (
        <div className="container mx-auto mt-5 mb-5">
            {isLoading ? (
                <div className="flex justify-center">
                    <div className="loading-spinner"></div>
                </div>
            ) : (
                <div>
                    <h2 className="text-center text-2xl font-bold mb-4">Detalhes Contratos por Dispensa de Licitação</h2>
                    <div className="border border-gray-400 p-4">
                        {dados?.nome && <p><strong>Edição:</strong> {dados.nome}</p>}
                        {dados?.data && <p><strong>Data:</strong> {dados.data}</p>}
                        {dados?.arquivo && (
                            <p><strong>Arquivo Diário:</strong> <button
                                className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm"
                                onClick={() => window.open(dados.arquivo, "_blank")}
                            >
                                Visualizar arquivo
                            </button></p>
                        )}
                        {typeof dados?.ativo !== 'undefined' && (
                            <p><strong>Ativo:</strong> {dados.ativo ? 'Sim' : 'Não'}</p>
                        )}
                    </div>

                    <div className="mt-4">
                        <Link to="/diario-assomasul" className="bg-blue-500 text-white py-2 px-4 rounded">Voltar</Link>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DiarioAntigoDetalhes;
