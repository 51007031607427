import React, { useState } from 'react';
import { Link, useNavigate   } from 'react-router-dom'; // Importe o Link do React Router
import './Topo.scss';
import brasaoImg from '../assets/images/brasao.png';
import logoPortalTransparenciaImg from '../assets/images/logo_portaltransparencia.png';
import esicImg from '../assets/images/esic.png';

const Topo: React.FC = () => {
    const [query, setQuery] = useState('');
    const navigate = useNavigate();

    const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); // Previne o comportamento padrão do formulário
        console.log("Pesquisando:", query); // Aqui você pode redirecionar ou chamar uma API de pesquisa
        // Por exemplo, para redirecionar para uma rota de pesquisa:
        navigate(`/pesquisar?query=${encodeURIComponent(query)}`);
    };

    return (
        <div>
            <div className="v4-tease"></div>
            <div className="logos shadow">
                <div className="container">
                    <div className="row vertical-center header">
                        <div className="col-md-2 text-center">
                            <div className="flex justify-center">
                                <Link to="/">
                                    <img
                                        src={brasaoImg}
                                        className="img-responsive"
                                        title="Prefeitura Municipal de Anastácio-MS"
                                        alt="Prefeitura Municipal de Anastácio-MS"
                                    />
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-9 col-xs-12 text-center">
                            <div className="row">
                                <div className="col-md-5 col-xs-12">
                                    <img
                                        src={logoPortalTransparenciaImg}
                                        className="img-responsive"
                                        title="Portal da Transparência"
                                        alt="Portal da Transparência"
                                    />
                                </div>
                                <div className="col-md-7 col-xs-12">
                                    <br />
                                    <form id="pesquisa" onSubmit={handleSearch}>
                                        <div className="container-3">
                                            <span className="icon">
                                                <i className="fa fa-search"></i>
                                            </span>
                                            <input
                                                type="search"
                                                id="search"
                                                name="text"
                                                placeholder="Faça sua pesquisa.."
                                                value={query}
                                                onChange={(e) => setQuery(e.target.value)}
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1 col-xs-12 text-center">
                            <a
                                href="http://sic.anastacio.ms.gov.br/"
                                target="_blank"
                                rel="noopener noreferrer"
                                data-toggle="tooltip"
                                title="E-sic"
                            >
                                <img src={esicImg} alt="Esic" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Topo;
