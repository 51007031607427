import React from 'react';
import { Link } from 'react-router-dom';
import './NotFoundPage.scss';

const NotFoundPage: React.FC = () => {
  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <h1 className="text-4xl">Oops!</h1>
        <p>A página que você está procurando não pôde ser encontrada.</p>
        <p>Por favor, volte para <Link to="/">página inicial</Link>.</p>
      </div>
      <div className="not-found-animation">
        <div className="spinner"></div>
      </div>
    </div>
  );
}

export default NotFoundPage;
