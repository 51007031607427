import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Api from '../../Api';
import Pagination from '../../components/Pagination';
import moment from 'moment';
import NavigationButtons from '../../components/NavigationButtons';

interface Dados {
    id: number;
    nome: string;
    data: string;
    arquivo: string;
}

interface Links {
    url: string | null;
    label: string;
    active: boolean;
}

interface ApiResponse {
    data: Dados[];
    links: Links[];
    total: number;
    last_page: number;
}

const Diario: React.FC = () => {
    const [dados, setDados] = useState<ApiResponse | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [clickedLinks, setClickedLinks] = useState<number[]>([]);
    const navigate = useNavigate();
    const location = useLocation();

    const query = new URLSearchParams(location.search);
    const currentPage = parseInt(query.get('page') || '1', 10);

    useEffect(() => {
        const fetchDados = async () => {
            try {
                setIsLoading(true);
                const params = new URLSearchParams();

                params.append('offset', '0');
                params.append('take', '15');
                params.append('skip', '0');
                params.append('pageSize', '15');

                if (searchText) {
                    params.append('filter[logic]', 'and');
                    params.append('filter[filters][0][field]', 'an_diario.nome');
                    params.append('filter[filters][0][operator]', 'contains');
                    params.append('filter[filters][0][value]', searchText);
                    params.append('page', '1');
                } else {
                    params.append('page', currentPage.toString());
                }

                const response = await Api.get<ApiResponse>(`diario/all?offset=8&${params.toString()}`);
                setDados(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching atas:', error);
                setIsLoading(false);
            }
        };

        fetchDados();

        // Load clicked links from sessionStorage
        const savedClickedLinks = sessionStorage.getItem('clickedLinks');
        if (savedClickedLinks) {
            setClickedLinks(JSON.parse(savedClickedLinks));
        }
    }, [currentPage, searchText]);

    const handlePageChange = (page: number) => {
        navigate(`?page=${page}&searchText=${searchText}`);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
    };

    const formatDate = (date: string | null | undefined): string => {
        if (!date) {
            return '';
        }
        return moment(date).format('DD/MM/YYYY');
    };

    const handleLinkClick = (id: number) => {
        const updatedClickedLinks = [...clickedLinks, id];
        setClickedLinks(updatedClickedLinks);
        sessionStorage.setItem('clickedLinks', JSON.stringify(updatedClickedLinks));
    };

    return (
        <div className="container mx-auto mt-5 mb-5">
            <NavigationButtons />
            <div className="flex flex-col md:flex-row justify-between mb-4">
                <form onSubmit={handleSubmit} className="flex flex-col md:flex-row">
                    <input
                        type="text"
                        placeholder="Pesquisar por nome"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        className="p-2 border border-gray-400 rounded h-10 md:mr-2"
                    />
                </form>
            </div>
            {isLoading ? (
                <div className="flex justify-center">
                    <div className="loading-spinner"></div>
                </div>
            ) : (
                <div className="overflow-x-auto">
                    <div className="flex">
                        <div className="flex-1">
                            <h2 className="text-center text-2xl font-bold mb-4">Diário Oficial</h2>
                            <p>OS ATOS OFICIAIS SÃO PUBLICADOS NO DIÁRIO OFICIAL DO MUNICÍPIO DE ANASTÁCIO.</p>
                            <p>ESCOLHA UMA DATA ABAIXO E CLIQUE PARA ABRIR A PUBLICAÇÃO DO DIÁRIO CORRESPONDENTE:</p>
                        </div>
                        <div className="flex2 mt-2">
                            <Link to="/diario-assomasul" className="bg-blue-500 text-white py-2 px-4 rounded">Diário Assomasul</Link>
                        </div>
                    </div>
                    <table className="border-collapse border border-gray-400 w-full mt-3">
                        <thead className="bg-gray-200">
                            <tr>
                                <th className="border border-gray-400 p-2">Texto</th>
                                <th className="border border-gray-400 p-2">Data</th>
                                <th className="border border-gray-400 p-2"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {dados?.data.map(d => (
                                <tr key={d.id}>
                                    <td className="border border-gray-400 p-2 w-1/2">{d.nome}</td>
                                    <td className="border border-gray-400 p-2 w-1/2">{formatDate(d.data)}</td>
                                    <td className="border border-gray-400 p-2">
                                        {d?.arquivo && (
                                            <Link
                                                to={d.arquivo}
                                                target="_blank"
                                                className={`py-2 px-4 rounded ${clickedLinks.includes(d.id) ? 'bg-blue-800 text-white' : 'bg-blue-500 text-white'}`}
                                                onClick={() => handleLinkClick(d.id)}
                                            >
                                                Arquivo
                                            </Link>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
            <Pagination
                totalPages={dados?.last_page || 0}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                totalRecords={dados?.total || 0}
            />
        </div>
    );
};

export default Diario;
