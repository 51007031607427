import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Topo from './components/Topo'
import Rodape from './components/Rodape'
import HomePage from './pages/Home'

import AtasRegistro from './pages/AtasRegistro'
import AtasRegistroDetalhes from './pages/AtasRegistro/Detalhes'
import AtasPublicacoes from './pages/AtasPublicacoes'
import AtasPublicacoesDetalhes from './pages/AtasPublicacoes/Detalhes'
import ChamadaPublica from './pages/ChamadaPublica'
import ChamadaPublicaDetalhes from './pages/ChamadaPublica/Detalhes'
import Tributario from './pages/Tributario'
import TributarioDetalhes from './pages/Tributario/Detalhes'
import Concursos from './pages/Concursos'
import ConcursosDetalhes from './pages/Concursos/Detalhes'
import Contratos from './pages/Contratos'
import ContratosDetalhes from './pages/Contratos/Detalhes'
import Convenios from './pages/Convenios'
import ConvenioMunicipal from './pages/ConvenioMunicipal'
import ConvenioMunicipalDetalhes from './pages/ConvenioMunicipal/Detalhes'
import Decretos from './pages/Decretos'
import DecretosDetalhes from './pages/Decretos/Detalhes'
import Diarias from './pages/Diarias'
import DiariasDetalhes from './pages/Diarias/Detalhes'
import Dispensa from './pages/Dispensa'
import DispensaDetalhes from './pages/Dispensa/Detalhes'
import Diario from './pages/Diario'
import DiarioDetalhes from './pages/Diario/Detalhes'
import DiarioAntigo from './pages/DiarioAntigo'
import DiarioAntigoDetalhes from './pages/DiarioAntigo/Detalhes'
import Extratos from './pages/Extratos'
import ExtratosDetalhes from './pages/Extratos/Detalhes'
import Inexigibilidade from './pages/Inexigibilidade'
import InexigibilidadeDetalhes from './pages/Inexigibilidade/Detalhes'
import Leis from './pages/Leis'
import LeisDetalhes from './pages/Leis/Detalhes'
import Licitacao from './pages/Licitacao'
import LicitacaoDetalhes from './pages/Licitacao/Detalhes'
import Parecer from './pages/Parecer'
import ParecerDetalhes from './pages/Parecer/Detalhes'
import Portarias from './pages/Portarias'
import PortariasDetalhes from './pages/Portarias/Detalhes'
import PrestacaoContas from './pages/PrestacaoContas'
import PrestacaoContasDetalhes from './pages/PrestacaoContas/Detalhes'
import Rreo from './pages/Rreo'
import RreoDetalhes from './pages/Rreo/Detalhes'
import Rgf from './pages/Rgf'
import RgfDetalhes from './pages/Rgf/Detalhes'
import Siafic from './pages/Siafic'
import SiaficDetalhes from './pages/Siafic/Detalhes'
import Covid19 from './pages/Covid19'
import Covid19Detalhes from './pages/Covid19/Detalhes'
import Resolucoes from './pages/Resolucoes'
import ResolucoesDetalhes from './pages/Resolucoes/Detalhes'
import VtnItr from './pages/VtnItr'
import VtnItrDetalhes from './pages/VtnItr/Detalhes'
import Search from './pages/Search'

import NotFoundPage from './pages/NotFoundPage'
import './App.scss'

const App: React.FC = () => {
  return (
    <BrowserRouter>
        <Topo />
      <Routes>
        <Route path="/" element={<HomePage />} /> 
        <Route path="/atasregistro" element={<AtasRegistro />} />
        <Route path="/atasregistro/detalhes/:id" element={<AtasRegistroDetalhes />} />
        <Route path="/ataspublicacoes" element={<AtasPublicacoes />} />
        <Route path="/ataspublicacoes/detalhes/:id" element={<AtasPublicacoesDetalhes />} />
        <Route path="/chamadapublica" element={<ChamadaPublica />} />
        <Route path="/chamadapublica/detalhes/:id" element={<ChamadaPublicaDetalhes />} />
        <Route path="/tributario" element={<Tributario />} />
        <Route path="/tributario/detalhes/:id" element={<TributarioDetalhes />} />
        <Route path="/concursos" element={<Concursos />} />
        <Route path="/concursos/detalhes/:id" element={<ConcursosDetalhes />} />
        <Route path="/contratos" element={<Contratos />} />
        <Route path="/contratos/detalhes/:id" element={<ContratosDetalhes />} />
        <Route path="/convenios" element={<Convenios />} />
        <Route path="/convenio-municipal" element={<ConvenioMunicipal />} />
        <Route path="/convenio-municipal/detalhes/:id" element={<ConvenioMunicipalDetalhes />} />
        <Route path="/decretos" element={<Decretos />} />
        <Route path="/decretos/detalhes/:id" element={<DecretosDetalhes />} />
        <Route path="/diarias" element={<Diarias />} />
        <Route path="/diarias/detalhes/:id" element={<DiariasDetalhes />} />
        <Route path="/dispensa" element={<Dispensa />} />
        <Route path="/dispensa/detalhes/:id" element={<DispensaDetalhes />} />
        <Route path="/diario" element={<Diario />} />
        <Route path="/diario/detalhes/:id" element={<DiarioDetalhes />} />
        <Route path="/diario-assomasul" element={<DiarioAntigo />} />
        <Route path="/diario-assomasul/detalhes/:id" element={<DiarioAntigoDetalhes />} />
        <Route path="/extratos" element={<Extratos />} />
        <Route path="/extratos/detalhes/:id" element={<ExtratosDetalhes />} />
        <Route path="/inexigibilidade" element={<Inexigibilidade />} />
        <Route path="/inexigibilidade/detalhes/:id" element={<InexigibilidadeDetalhes />} />
        <Route path="/leis" element={<Leis />} />
        <Route path="/leis/detalhes/:id" element={<LeisDetalhes />} />
        <Route path="/licitacao" element={<Licitacao />} />
        <Route path="/licitacao/detalhes/:id" element={<LicitacaoDetalhes />} />
        <Route path="/parecer" element={<Parecer />} />
        <Route path="/parecer/detalhes/:id" element={<ParecerDetalhes />} />
        <Route path="/portarias" element={<Portarias />} />
        <Route path="/portarias/detalhes/:id" element={<PortariasDetalhes />} />
        <Route path="/prestacao-contas" element={<PrestacaoContas />} />
        <Route path="/prestacao-contas/detalhes/:id" element={<PrestacaoContasDetalhes />} />
        <Route path="/rreo" element={<Rreo />} />
        <Route path="/rreo/detalhes/:id" element={<RreoDetalhes />} />
        <Route path="/rgf" element={<Rgf />} />
        <Route path="/rgf/detalhes/:id" element={<RgfDetalhes />} />
        <Route path="/siafic" element={<Siafic />} />
        <Route path="/siafic/detalhes/:id" element={<SiaficDetalhes />} />
        <Route path="/covid19" element={<Covid19 />} />
        <Route path="/covid19/detalhes/:id" element={<Covid19Detalhes />} />
        <Route path="/resolucoes" element={<Resolucoes />} />
        <Route path="/resolucoes/detalhes/:id" element={<ResolucoesDetalhes />} />
        <Route path="/vtn" element={<VtnItr />} />
        <Route path="/pesquisar" element={<Search />} />
        <Route path="/vtn-itr/detalhes/:id" element={<VtnItrDetalhes />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Rodape />
    </BrowserRouter>
  );
}

export default App;
