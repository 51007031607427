import axios, { AxiosInstance } from 'axios';

let baseURL = null
if (window.location.hostname === 'localhost') {
	baseURL = 'http://localhost:3055/api/v1/'
} else if (window.location.hostname === 'www.transparencia.anastacio.ms.gov.br' || window.location.hostname === 'transparencia.anastacio.ms.gov.br') {
	baseURL = 'https://server.anastacio.ms.gov.br/api/v1/'
} else {
	baseURL = 'https://api.anastacio.sistemaweb.com.br/api/v1/'
}

const Api: AxiosInstance = axios.create({
  baseURL
});

export default Api;
