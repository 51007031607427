import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Api from '../../Api';
import Pagination from '../../components/PaginationPesquisa';

interface Dados {
    id: number;
    texto: string;
    source: string;
}

interface Link {
    url: string | null;
    label: string;
    active: boolean;
}

interface ApiResponse {
    data: Dados[];
    links: Link[];
    total: number;
    last_page: number;
}

const Pesquisar: React.FC = () => {
    const [dados, setDados] = useState<ApiResponse | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const currentPage = parseInt(query.get('page') || '1', 10);
    const initialSearchText = query.get('query') || '';

    const [searchText, setSearchText] = useState(initialSearchText);

    useEffect(() => {
        setSearchText(initialSearchText);
    }, [initialSearchText]);

    useEffect(() => {
        const fetchDados = async () => {
            setIsLoading(true);
            const params = new URLSearchParams();
            params.append('page', currentPage.toString());
            params.append('pageSize', '15');
            params.append('query', searchText); // make sure to send searchText to your API if needed

            const response = await Api.get<ApiResponse>(`/pesquisar?${params.toString()}`);
            setDados(response.data);
            setIsLoading(false);
        };

        fetchDados();
    }, [currentPage, searchText]);

    const handlePageChange = (page: number) => {
        navigate(`?page=${page}&query=${encodeURIComponent(searchText)}`);
    };

    const highlightSearchText = (text: string, search: string) => {
        console.log(search, text)
        if (!search) return text;
        const regex = new RegExp(`(${search})`, 'gi');
        const parts = text.split(regex);
        return parts.map((part, index) => 
            regex.test(part) ? <span key={index} style={{ backgroundColor: 'yellow', fontWeight: 'bold' }}>{part}</span> : part
        );
    };

    return (
        <div className="container mx-auto mt-5 mb-5">
            {isLoading ? (
                <div className="flex justify-center">
                    <div className="loading-spinner"></div>
                </div>
            ) : (
                <div className="overflow-x-auto">
                    <h2 className="text-center text-2xl font-bold mb-4">Pesquisar</h2>
                    <table className="border-collapse border border-gray-400 w-full">
                        <thead className="bg-gray-200">
                            <tr>
                                <th className="border border-gray-400 p-2">Página</th>
                                <th className="border border-gray-400 p-2">Nome</th>
                                <th className="border border-gray-400 p-2"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {dados?.data.map(d => (
                                <tr key={d.id}>
                                    <td className="border border-gray-400 p-2 w-1/4">{d.source}</td>
                                    <td className="border border-gray-400 p-2 w-1/2">
                                        {searchText}-
                                        {highlightSearchText(d.texto, searchText)}
                                    </td>
                                    <td className="border border-gray-400 p-2 w-1/4">
                                        <button
                                            className="bg-blue-500 text-white py-2 px-4 rounded"
                                            onClick={() => navigate(`/${d.source}/detalhes/${d.id}`)}
                                        >
                                            Abrir
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
            <Pagination
                totalPages={dados?.last_page || 0}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                totalRecords={dados?.total || 0}
                params={{ query: searchText }}
            />
        </div>
    );
};

export default Pesquisar;
