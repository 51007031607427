import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom'; // Importação do Link
import Api from '../../Api';

interface Dados {
    nome?: string;
    cargo?: string;
    datainicio?: string;
    datafim?: string;
    destino?: string;
    justificativa?: string;
    valor?: string;
    ativo?: boolean;
}

const DiariasDetalhes: React.FC = () => {
    const [dados, setDados] = useState<Dados | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        const fetchDados = async () => {
            try {
                setIsLoading(true);
                const response = await Api.get<Dados>(`diarias/${id}`);
                setDados(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching ata:', error);
                setIsLoading(false);
            }
        };

        fetchDados();
    }, [id]);

    return (
        <div className="container mx-auto mt-5 mb-5">
            {isLoading ? (
                <div className="flex justify-center">
                    <div className="loading-spinner"></div>
                </div>
            ) : (
                <div>
                    <h2 className="text-center text-2xl font-bold mb-4">Detalhes Diárias Pagas - 2015/2016</h2>
                    <div className="border border-gray-400 p-4">
                        {dados?.nome && <p><strong>Nome:</strong> {dados.nome}</p>}
                        {dados?.cargo && <p><strong>Cargo:</strong> {dados.cargo}</p>}
                        {dados?.datainicio && <p><strong>Data Início:</strong> {dados.datainicio}</p>}
                        {dados?.datafim && <p><strong>Data Fim:</strong> {dados.datafim}</p>}
                        {dados?.destino && <p><strong>Destino:</strong> {dados.destino}</p>}
                        {dados?.justificativa && <p><strong>Justificativa:</strong> {dados.justificativa}</p>}
                        {dados?.valor && <p><strong>Valor:</strong> R$ {parseFloat(dados.valor).toFixed(2)}</p>}
                        {typeof dados?.ativo !== 'undefined' && (
                            <p><strong>Ativo:</strong> {dados.ativo ? 'Sim' : 'Não'}</p>
                        )}
                    </div>

                    <div className="mt-4">
                        <Link to="/diarias" className="bg-blue-500 text-white py-2 px-4 rounded">Voltar</Link>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DiariasDetalhes;
