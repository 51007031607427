import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom'; // Importação do Link
import Api from '../../Api';
import moment from 'moment';

interface Ata {
    id: number;
    texto: string;
    ano: string;
    objeto: string;
    datacad: string;
    codigo1?: string;
    codigo2?: string;
    codigo3?: string;
    data_pub?: string;
    edicao?: string;
    contratado?: string;
    valor?: string;
    contratado2?: string;
    valor2?: string;
    contratado3?: string;
    valor3?: string;
    contratado4?: string;
    valor4?: string;
    contratado5?: string;
    valor5?: string;
    contratado6?: string;
    valor6?: string;
    contratado7?: string;
    valor7?: string;
    contratado8?: string;
    valor8?: string;
    pz_datainicio?: string;
    pz_datafim?: string;
    data_hom?: string;
    arq_data_pub?: string;
    obs?: string;
}


const DetalhesAta: React.FC = () => {
    const [ata, setAta] = useState<Ata | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        const fetchAta = async () => {
            try {
                setIsLoading(true);
                const response = await Api.get<Ata>(`atas-registro/${id}`);
                setAta(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching ata:', error);
                setIsLoading(false);
            }
        };

        fetchAta();
    }, [id]);

    const formatDate = (date: string | null | undefined): string => {
        if (!date) {
            return ''
        }
        return moment(date).format('DD/MM/YYYY');
    };

    return (
        <div className="container mx-auto mt-5 mb-5">
            {isLoading ? (
                <div className="flex justify-center">
                    <div className="loading-spinner"></div>
                </div>
            ) : (
                <div>
                    <h2 className="text-center text-2xl font-bold mb-4">Detalhes da Atas de Registro de Preço</h2>
                    <div className="border border-gray-400 p-4">
                        {ata?.ano && <p><strong>Ano:</strong> {ata.ano}</p>}
                        {ata?.codigo1 && <p><strong>No do Contrato:</strong> {ata.codigo1}</p>}
                        {ata?.codigo2 && <p><strong>No da Licitação:</strong> {ata.codigo2}</p>}
                        {ata?.codigo3 && <p><strong>No do Processo:</strong> {ata.codigo3}</p>}
                        {ata?.data_pub && <p><strong>Data:</strong> {formatDate(ata.data_pub)}</p>}
                        {ata?.edicao && <p><strong>Edição:</strong> {ata.edicao}</p>}
                        {ata?.objeto && <p><strong>Objeto:</strong> {ata.objeto}</p>}
                        {ata?.contratado && <p><strong>1. Contratado:</strong> {ata.contratado}</p>}
                        {!!ata?.valor && <p><strong>1. Valor:</strong> {ata.valor}</p>}
                        {ata?.contratado2 && <p><strong>2. Contratado:</strong> {ata.contratado2}</p>}
                        {!!ata?.valor2 && <p><strong>2. Valor:</strong> {ata.valor2}</p>}
                        {ata?.contratado3 && <p><strong>3. Contratado:</strong> {ata.contratado3}</p>}
                        {!!ata?.valor3 && <p><strong>3. Valor:</strong> {ata.valor3}</p>}
                        {ata?.contratado4 && <p><strong>4. Contratado:</strong> {ata.contratado4}</p>}
                        {!!ata?.valor4 && <p><strong>4. Valor:</strong> {ata.valor4}</p>}
                        {ata?.contratado5 && <p><strong>5. Contratado:</strong> {ata.contratado5}</p>}
                        {!!ata?.valor5 && <p><strong>5. Valor:</strong> {ata.valor5}</p>}
                        {ata?.contratado6 && <p><strong>6. Contratado:</strong> {ata.contratado6}</p>}
                        {!!ata?.valor6 && <p><strong>6. Valor:</strong> {ata.valor6}</p>}
                        {ata?.contratado7 && <p><strong>7. Contratado:</strong> {ata.contratado7}</p>}
                        {!!ata?.valor7 && <p><strong>7. Valor:</strong> {ata.valor7}</p>}
                        {ata?.contratado8 && <p><strong>8. Contratado:</strong> {ata.contratado8}</p>}
                        {!!ata?.valor8 && <p><strong>8. Valor:</strong> {ata.valor8}</p>}
                        {/* Prazo de vigência do contrato não é diretamente representável como um único valor neste formato, então você pode querer tratar esses campos separadamente */}
                        {ata?.pz_datainicio && ata?.pz_datafim && (
                            <p><strong>Prazo de vigência do contrato:</strong> {ata.pz_datainicio} até {ata.pz_datafim}</p>
                        )}
                        {ata?.data_hom && <p><strong>Data da assinatura:</strong> {ata.data_hom}</p>}
                        {ata?.arq_data_pub && (
                            <p><strong>Arquivo Contrato:</strong>  <Link
                                className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm"
                                to={ata.arq_data_pub} target="_blank"
                            >
                                Visualizar contrato
                            </Link></p>
                        )}
                        {ata?.texto && <p><strong>Texto:</strong> {ata.texto}</p>}
                        {ata?.obs && <p><strong>Obs:</strong> {ata.obs}</p>}
                    </div>
                    {/* Botão de voltar */}
                    <div className="mt-4">
                        <Link to="/atasregistro" className="bg-blue-500 text-white py-2 px-4 rounded">Voltar</Link>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DetalhesAta;
