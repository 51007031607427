import React from 'react';
import { useNavigate, Link } from 'react-router-dom';

const NavigationButtons: React.FC = () => {
  const navigate = useNavigate();

  const handleBack = (): void => {
    navigate(-1);
  };

  return (
    <div className="flex justify-center mb-4">
      <Link to="#" onClick={(e) => {
        e.preventDefault();
        handleBack();
      }} className="bg-blue-500 text-white py-2 px-4 rounded">
        Voltar
      </Link>
      <Link to="https://anastacio.ms.gov.br/" className="bg-blue-500 text-white py-2 px-4 rounded ml-2">
        Prefeitura
      </Link>
      <Link to="/" className="bg-blue-500 text-white py-2 px-4 rounded ml-2">
        Transparência
      </Link>
    </div>
  );
};

export default NavigationButtons;
