import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom'; // Importação do Link
import Api from '../../Api';

interface Ata {
    id: number;
    processo?: string;
    licitacao?: string;
    ata_registro?: string;
    ano?: string;
    vig_inicial?: string;
    vig_final?: string;
    objeto?: string;
    obs?: string;
    arq1?: string;
    arq2?: string;
    arq3?: string;
    arq4?: string;
    arq5?: string;
    arq6?: string;
    arq7?: string;
    arq8?: string;
    arq9?: string;
    arq10?: string;
    arq_data_pub?: string;
}



const AtasPublicacoesDetalhes: React.FC = () => {
    const [ata, setAta] = useState<Ata | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        const fetchAta = async () => {
            try {
                setIsLoading(true);
                const response = await Api.get<Ata>(`atas-publicacoes/${id}`);
                setAta(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching ata:', error);
                setIsLoading(false);
            }
        };

        fetchAta();
    }, [id]);

    return (
        <div className="container mx-auto mt-5 mb-5">
            {isLoading ? (
                <div className="flex justify-center">
                    <div className="loading-spinner"></div>
                </div>
            ) : (
                <div>
                    <h2 className="text-center text-2xl font-bold mb-4">Detalhes da Atas - Publicações Trimestrais</h2>
                    <div className="border border-gray-400 p-4">
                        {ata?.processo && <p><strong>Processo Número:</strong> {ata.processo}</p>}
                        {ata?.licitacao && <p><strong>Licitação Número:</strong> {ata.licitacao}</p>}
                        {ata?.ata_registro && <p><strong>Ata de registro preço:</strong> {ata.ata_registro}</p>}
                        {ata?.ano && <p><strong>Ano:</strong> {ata.ano}</p>}
                        {ata?.vig_inicial && <p><strong>Vigência (Data Inicial):</strong> {ata.vig_inicial}</p>}
                        {ata?.vig_final && <p><strong>Vigência (Data Final):</strong> {ata.vig_final}</p>}
                        {ata?.objeto && <p><strong>Objeto:</strong> {ata.objeto}</p>}
                        {ata?.obs && <p><strong>Obs:</strong> {ata.obs}</p>}
                        {ata?.arq1 && (
                            <p>
                                <strong>Arquivo 1:</strong>
                                <button
                                    className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm"
                                    onClick={() => window.open(ata.arq1, "_blank")}
                                >
                                    Visualizar
                                </button>
                            </p>
                        )}
                        {ata?.arq2 && (
                            <p>
                                <strong>Arquivo 2:</strong>
                                <button
                                    className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm"
                                    onClick={() => window.open(ata.arq2, "_blank")}
                                >
                                    Visualizar
                                </button>
                            </p>
                        )}
                        {ata?.arq3 && (
                            <p>
                                <strong>Arquivo 3:</strong>
                                <button
                                    className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm"
                                    onClick={() => window.open(ata.arq3, "_blank")}
                                >
                                    Visualizar
                                </button>
                            </p>
                        )}
                        {ata?.arq4 && (
                            <p>
                                <strong>Arquivo 4:</strong>
                                <button
                                    className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm"
                                    onClick={() => window.open(ata.arq4, "_blank")}
                                >
                                    Visualizar
                                </button>
                            </p>
                        )}
                        {ata?.arq5 && (
                            <p>
                                <strong>Arquivo 5:</strong>
                                <button
                                    className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm"
                                    onClick={() => window.open(ata.arq5, "_blank")}
                                >
                                    Visualizar
                                </button>
                            </p>
                        )}
                        {ata?.arq6 && (
                            <p>
                                <strong>Arquivo 6:</strong>
                                <button
                                    className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm"
                                    onClick={() => window.open(ata.arq6, "_blank")}
                                >
                                    Visualizar
                                </button>
                            </p>
                        )}
                        {ata?.arq7 && (
                            <p>
                                <strong>Arquivo 7:</strong>
                                <button
                                    className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm"
                                    onClick={() => window.open(ata.arq7, "_blank")}
                                >
                                    Visualizar
                                </button>
                            </p>
                        )}
                        {ata?.arq8 && (
                            <p>
                                <strong>Arquivo 8:</strong>
                                <button
                                    className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm"
                                    onClick={() => window.open(ata.arq8, "_blank")}
                                >
                                    Visualizar
                                </button>
                            </p>
                        )}
                        {ata?.arq9 && (
                            <p>
                                <strong>Arquivo 9:</strong>
                                <button
                                    className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm"
                                    onClick={() => window.open(ata.arq9, "_blank")}
                                >
                                    Visualizar
                                </button>
                            </p>
                        )}
                        {ata?.arq10 && (
                            <p>
                                <strong>Arquivo 10:</strong>
                                <button
                                    className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm"
                                    onClick={() => window.open(ata.arq10, "_blank")}
                                >
                                    Visualizar
                                </button>
                            </p>
                        )}
                    </div>

                    {/* Botão de voltar */}
                    <div className="mt-4">
                        <Link to="/ataspublicacoes" className="bg-blue-500 text-white py-2 px-4 rounded">Voltar</Link>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AtasPublicacoesDetalhes;
