import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom'; // Importação do Link
import Api from '../../Api';

interface Dados {
    ano?: string;
    codigo1?: string;
    codigo2?: string;
    codigo3?: string;
    data_pub?: string;
    edicao?: string;
    objeto?: string;
    contratado?: string;
    valor?: number;
    pz_datainicio?: string;
    pz_datafim?: string;
    data_hom?: string;
    arquivos?: string;
    arq_data_pub?: string;
    arq_data_pub1?: string;
    arq_data_pub2?: string;
    arq_data_pub3?: string;
    arq_data_pub4?: string;
    texto?: string;
    obs?: string;
}


const ChamadaPublicaDetalhes: React.FC = () => {
    const [dados, setDados] = useState<Dados | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        const fetchDados = async () => {
            try {
                setIsLoading(true);
                const response = await Api.get<Dados>(`chamada-publica/${id}`);
                setDados(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching ata:', error);
                setIsLoading(false);
            }
        };

        fetchDados();
    }, [id]);

    return (
        <div className="container mx-auto mt-5 mb-5">
            {isLoading ? (
                <div className="flex justify-center">
                    <div className="loading-spinner"></div>
                </div>
            ) : (
                <div>
                    <h2 className="text-center text-2xl font-bold mb-4">Detalhes da Chamada Pública</h2>
                    <div className="border border-gray-400 p-4">
                        {dados?.ano && <p><strong>Ano:</strong> {dados.ano}</p>}
                        {dados?.codigo1 && <p><strong>Nº do Contrato:</strong> {dados.codigo1}</p>}
                        {dados?.codigo2 && <p><strong>Nº da Licitação:</strong> {dados.codigo2}</p>}
                        {dados?.codigo3 && <p><strong>Nº do Processo:</strong> {dados.codigo3}</p>}
                        {dados?.data_pub && <p><strong>Data de Publicação:</strong> {dados.data_pub}</p>}
                        {dados?.edicao && <p><strong>Edição:</strong> {dados.edicao}</p>}
                        {dados?.objeto && <p><strong>Objeto:</strong> {dados.objeto}</p>}
                        {dados?.contratado && <p><strong>Contratado:</strong> {dados.contratado}</p>}
                        {!!dados?.valor && <p><strong>Valor:</strong> R$ {dados.valor.toLocaleString('pt-BR')}</p>}
                        {dados?.pz_datainicio && dados?.pz_datafim && (
                            <p><strong>Prazo de vigência do contrato:</strong> {dados.pz_datainicio} até {dados.pz_datafim}</p>
                        )}
                        {dados?.data_hom && <p><strong>Data da assinatura:</strong> {dados.data_hom}</p>}
                        {dados?.arquivos && (
                            <p className="mb-2"><strong>Arquivo:</strong>  <button
                                className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm"
                                onClick={() => window.open(dados.arquivos, "_blank")}
                            >
                                Visualizar arquivo
                            </button></p>
                        )}
                        {dados?.arq_data_pub && (
                            <p className="mb-2"><strong>Arquivo Contrato:</strong>  <button
                                className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm"
                                onClick={() => window.open(dados.arq_data_pub, "_blank")}
                            >
                                Visualizar contrato
                            </button></p>
                        )}
                        {dados?.arq_data_pub1 && (
                            <p className="mb-2"><strong>Arquivo Contrato 2:</strong> <button
                                className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm"
                                onClick={() => window.open(dados.arq_data_pub1, "_blank")}
                            >
                                Visualizar contrato
                            </button></p>
                        )}
                        {dados?.arq_data_pub2 && (
                            <p className="mb-2"><strong>Arquivo Contrato 3:</strong> <button
                                className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm"
                                onClick={() => window.open(dados.arq_data_pub2, "_blank")}
                            >
                                Visualizar contrato
                            </button></p>
                        )}
                        {dados?.arq_data_pub3 && (
                            <p className="mb-2"><strong>Arquivo Contrato 4:</strong> <button
                                className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm"
                                onClick={() => window.open(dados.arq_data_pub3, "_blank")}
                            >
                                Visualizar contrato
                            </button></p>
                        )}
                        {dados?.arq_data_pub4 && (
                            <p className="mb-2"><strong>Arquivo Contrato 5:</strong> <button
                                className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm"
                                onClick={() => window.open(dados.arq_data_pub4, "_blank")}
                            >
                                Visualizar contrato
                            </button></p>
                        )}
                        {dados?.texto && <p><strong>Descrição:</strong> {dados.texto}</p>}
                        {dados?.obs && <p><strong>Obs:</strong> {dados.obs}</p>}
                    </div>


                    {/* Botão de voltar */}
                    <div className="mt-4">
                        <Link to="/chamadapublica" className="bg-blue-500 text-white py-2 px-4 rounded">Voltar</Link>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChamadaPublicaDetalhes;
