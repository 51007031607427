import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Importando o componente Link
import Api from '../../Api';
import './Home.scss';

interface MenuItem {
    id: number;
    titulo: string;
    url: string;
    icone: string;
    target: string;
    destaque: string;
    ativo: number;
}

const Home: React.FC = () => {
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchMenuItems = async () => {
            try {
                const response = await Api.get<MenuItem[]>('transparencia-menu?ativo=1');
                setMenuItems(response.data);
                setIsLoading(false); 
            } catch (error) {
                console.error('Error fetching menu items:', error);
                setIsLoading(false); 
            }
        };

        fetchMenuItems();
    }, []);

    return (
        <div className="container mt-5 mb-5">
            {isLoading ? ( // Renderizar o loading enquanto isLoading for verdadeiro
                <div className="center">
                    <div className="loading-spinner"></div>
                </div>
            ) : (
                <div className="section-padding">
                    <div className="page-title text-center">
                        <h4 className="mb-5">Espaço destinado a facilitar, ao cidadão, o acesso às informações da gestão municipal,<br />referentes aos
                            orçamentos, gastos e investimentos do município.</h4>
                        <hr className="pg-titl-bdr-btm" />
                    </div>
                    <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-7 gap-4 my-10">
                        {menuItems.map(item => (
                            <div key={item.id} className="text-center">
                                <Link to={item.url} target={item.target} className="service">
                                    <div className="service-box">
                                        <div className={`service-icon ${item.destaque ? 'active' : ''}`}><img src={item.icone} alt={item.titulo} /></div>
                                        <div className="service-text">
                                            <h4>{item.titulo}</h4>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Home;
