import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Api from '../../Api';
import Pagination from '../../components/Pagination';
import NavigationButtons from '../../components/NavigationButtons';

interface Dados {
    id: number;
    nome: string;
    ano: string;
    data: string;
    datacad: string;
}

interface Link {
    url: string | null;
    label: string;
    active: boolean;
}

interface ApiResponse {
    data: Dados[];
    links: Link[];
    total: number;
    last_page: number;
}

const Concursos: React.FC = () => {
    const [dados, setDados] = useState<ApiResponse | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [years, setYears] = useState<string[]>([]);
    const navigate = useNavigate();
    const location = useLocation();

    const query = new URLSearchParams(location.search);
    const currentPage = parseInt(query.get('page') || '1', 10);

    useEffect(() => {
        const fetchDados = async () => {
            try {
                setIsLoading(true);
                const params = new URLSearchParams();

                params.append('offset', '0');
                params.append('take', '15');
                params.append('skip', '0');
                params.append('pageSize', '15');


                if (searchText) {
                    params.append('filter[logic]', 'and');
                    params.append('filter[filters][0][field]', 'nome');
                    params.append('filter[filters][0][operator]', 'contains');
                    params.append('filter[filters][0][value]', searchText);
                    params.append('page', '1');
                } else {
                    params.append('page', currentPage.toString());
                }
                if (selectedYear) {
                    params.append('filter[logic]', 'and'); // Modificado para 'or'
                    params.append('filter[filters][1][field]', 'ano');
                    params.append('filter[filters][1][operator]', 'contains');
                    params.append('filter[filters][1][value]', selectedYear);
                    params.append('page', '1');
                } else {
                    params.append('page', currentPage.toString());
                }

                const response = await Api.get<ApiResponse>(`concursos/all?${params.toString()}`);
                setDados(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching atas:', error);
                setIsLoading(false);
            }
        };

        const fetchYears = async () => {
            try {
                const response = await Api.get<{ ano: string }[]>('concursos/ano');
                const years = response.data.map(item => item.ano);
                setYears(years);
            } catch (error) {
                console.error('Error fetching years:', error);
            }
        };

        fetchDados();
        fetchYears();
    }, [currentPage, searchText, selectedYear]);

    const handlePageChange = (page: number) => {
        navigate(`?page=${page}&searchText=${searchText}&selectedYear=${selectedYear}`);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
    };

    return (
        <div className="container mx-auto mt-5 mb-5">
             <NavigationButtons />
            <div className="flex flex-col md:flex-row justify-between mb-4">
                <form onSubmit={handleSubmit} className="flex flex-col md:flex-row">
                    <select
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(e.target.value)}
                        className="p-2 border border-gray-400 rounded mb-2 md:mb-0 md:mr-3 h-10"
                    >
                        <option value="">Todos</option>
                        {years.map(year => (
                            <option key={year} value={year}>{year}</option>
                        ))}
                    </select>
                    <input
                        type="text"
                        placeholder="Pesquisar por nome"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        className="p-2 border border-gray-400 rounded h-10 md:mr-2"
                    />
                </form>


            </div>
            {isLoading ? (
                <div className="flex justify-center">
                    <div className="loading-spinner"></div>
                </div>
            ) : (
                <div className="overflow-x-auto">
                    <h2 className="text-center text-2xl font-bold mb-4">Concursos</h2>
                    <table className="border-collapse border border-gray-400 w-full">
                        <thead className="bg-gray-200">
                            <tr>
                                <th className="border border-gray-400 p-2">Nome</th>
                                <th className="border border-gray-400 p-2">Ano</th>
                                <th className="border border-gray-400 p-2">Data</th>
                                <th className="border border-gray-400 p-2"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {dados?.data.map(d => (
                                <tr key={d.id}>
                                    <td className="border border-gray-400 p-2  w-1/2">{d.nome}</td>
                                    <td className="border border-gray-400 p-2  w-1/4">{d.ano}</td>
                                    <td className="border border-gray-400 p-2  w-1/4">{d.data}</td>
                                    <td className="border border-gray-400 p-2  w-1/4">
                                        <button
                                            className="bg-blue-500 text-white py-2 px-4 rounded"
                                            onClick={() => navigate(`/concursos/detalhes/${d.id}`)}
                                        >
                                            Abrir
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
            <Pagination
                totalPages={dados?.last_page || 0}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                totalRecords={dados?.total || 0}
            />
        </div>
    );
};

export default Concursos;
