import React from 'react';
import './Rodape.scss'; // Importe os estilos do rodapé se necessário

const Rodape = () => {
    return (
        <div className="footer">
            <div className="container">
                <div id="rodape" className="rodape-trans">
                    <div className="row">
                        <div className="col-md-3 col-sm-6 footer-col mb-4 mb-sm-0">
                            <div className="logofooter">Anastácio-MS</div>
                            <p><i className="fa fa-map-pin"></i> R. João Leite Ribeiro, 754 - Centro</p>
                            <p><i className="fa fa-phone"></i> (tel) (67) 3245-3540</p>
                        </div>
                        
                        <div className="col-md-6 col-sm-12 footer-col">
                            <p className="copyright">© 2024 - Prefeitura de Anastácio-MS - Developer: <a href="http://www.sistemaweb.com.br/"
                                    target="_black" className="dev">SistemaWeb.com.br</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Rodape;
