import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

interface PaginationProps {
    totalPages: number;
    currentPage: number;
    onPageChange: (page: number) => void;
    totalRecords: number;
    params?: { [key: string]: string }; // Objeto genérico para parâmetros adicionais
}

const Pagination: React.FC<PaginationProps> = ({ totalPages, currentPage, onPageChange, totalRecords, params }) => {
    const minPagesToShow = 4;
    const navigate = useNavigate();
    const lastPageRef = useRef<number>(currentPage);

    useEffect(() => {
        if (lastPageRef.current !== currentPage) {
            let url = `?page=${currentPage}`;
            if (params) {
                // Adicionando outros parâmetros à URL
                for (const key in params) {
                    if (params[key]) {
                        url += `&${key}=${encodeURIComponent(params[key])}`;
                    }
                }
            }
            navigate(url, { replace: true });
            lastPageRef.current = currentPage;
        }
    }, [currentPage, navigate, params]);

    let pagesToShow: number[] = [];
    // eslint-disable-next-line no-self-compare
    if (totalPages <= minPagesToShow) {
        pagesToShow = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
        const minPage = Math.max(1, currentPage - 1);
        const maxPage = Math.min(totalPages, currentPage + 1);
        pagesToShow = Array.from({ length: maxPage - minPage + 1 }, (_, i) => minPage + i);
    }

    return (
        <div className="flex justify-center items-center mt-4">
            <span className="mr-4">{`Total de registros: ${totalRecords}`}</span> {/* Exibindo o total de registros */}
            <button
                disabled={currentPage === 1}
                onClick={() => onPageChange(1)}
                className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-4"
            >
                <i className="fas fa-angle-double-left"></i>
            </button>
            <button
                disabled={currentPage === 1}
                onClick={() => onPageChange(currentPage - 1)}
                className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-4 rounded-l"
            >
                <i className="fas fa-angle-left"></i>
            </button>
            {pagesToShow.map(page => (
                <button
                    key={page}
                    onClick={() => onPageChange(page)}
                    className={`bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-4 ${currentPage === page ? 'bg-gray-300' : ''}`}
                >
                    {page}
                </button>
            ))}
            <button
                disabled={currentPage === totalPages}
                onClick={() => onPageChange(currentPage + 1)}
                className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-4"
            >
                <i className="fas fa-angle-right"></i>
            </button>
            <button
                disabled={currentPage === totalPages}
                onClick={() => onPageChange(totalPages)}
                className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-4 rounded-r"
            >
                <i className="fas fa-angle-double-right"></i>
            </button>
        </div>
    );
};

export default Pagination;
