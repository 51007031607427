import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom'; // Importação do Link
import Api from '../../Api';

interface Dados {
    ano?: string;
    codigo1?: string;
    codigo2?: string;
    objeto?: string;
    data_pub?: string;
    data_abert?: string;
    valor?: string;
    data_hom?: string;
    ata?: string;
    arq_data_pub?: string;
    arq_ata?: string;
    tomada_preco_titulo1?: string;
    tomada_preco_arq1?: string;
    tomada_preco_titulo2?: string;
    tomada_preco_arq2?: string;
    tomada_preco_titulo3?: string;
    tomada_preco_arq3?: string;
    tomada_preco_titulo4?: string;
    tomada_preco_arq4?: string;
    tomada_preco_titulo5?: string;
    tomada_preco_arq5?: string;
    tomada_preco_titulo6?: string;
    tomada_preco_arq6?: string;
    tomada_preco_titulo7?: string;
    tomada_preco_arq7?: string;
    tomada_preco_titulo8?: string;
    tomada_preco_arq8?: string;
    tomada_preco_titulo9?: string;
    tomada_preco_arq9?: string;
    tomada_preco_titulo10?: string;
    tomada_preco_arq10?: string;
    termo?: string;
    ata_preco?: string;
    texto?: string;
    obs?: string;
    cat?: any;
    [key: string]: string | undefined;
}


const CLicitacaoDetalhes: React.FC = () => {
    const [dados, setDados] = useState<Dados | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        const fetchDados = async () => {
            try {
                setIsLoading(true);
                const response = await Api.get<Dados>(`arquivos/${id}`);
                setDados(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching ata:', error);
                setIsLoading(false);
            }
        };

        fetchDados();
    }, [id]);

    return (
        <div className="container mx-auto mt-5 mb-5">
            {isLoading ? (
                <div className="flex justify-center">
                    <div className="loading-spinner"></div>
                </div>
            ) : (
                <div>
                    <h2 className="text-center text-2xl font-bold mb-4">Detalhes Licitação Editais - {dados?.cat !== 6 ? 'Licitação' : 'Pregão'}</h2>
                    {dados?.cat !== 6 ? (
                        <div className="border border-gray-400 p-4">

                            {dados?.ano && <p><strong>Ano:</strong> {dados.ano}</p>}
                            {dados?.codigo1 && <p><strong>Nº do Processo/ Nº do Edital:</strong> {dados.codigo1}</p>}
                            {dados?.codigo2 && <p><strong>Nº da Licitação:</strong> {dados.codigo2}</p>}
                            {dados?.objeto && <p><strong>Objeto:</strong> {dados.objeto}</p>}
                            {dados?.data_pub && <p><strong>Data de Publicação:</strong> {dados.data_pub}</p>}
                            {dados?.data_abert && <p><strong>Data da Abertura da Licitação:</strong> {dados.data_abert}</p>}
                            {dados?.valor && <p><strong>Valor:</strong> {dados.valor}</p>}
                            {dados?.data_hom && <p><strong>Data da Homologação/Adjudicação:</strong> {dados.data_hom}</p>}
                            {dados?.ata && <p><strong>Ata de Abertura e Julgamento:</strong> {dados.ata}</p>}
                            {dados?.arq_data_pub && (
                                <p><strong>Arquivo Edital:</strong>
                                    <a href={dados.arq_data_pub} target="_blank" rel="noopener noreferrer" className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm">Visualizar arquivo</a>
                                </p>
                            )}
                            {dados?.arq_ata && (
                                <p><strong>Arquivo Ata:</strong>
                                    <a href={dados.arq_ata} target="_blank" rel="noopener noreferrer" className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm">Visualizar arquivo</a>
                                </p>
                            )}
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => {
                                const tituloKey = `tomada_preco_titulo${i}`;
                                const arqKey = `tomada_preco_arq${i}`;
                                return (
                                    <React.Fragment key={i}>
                                        {dados && dados[tituloKey] && <p><strong>Tomada de Preço - Titulo {i}:</strong> {dados[tituloKey]}</p>}
                                        {dados && dados[arqKey] && (
                                            <p><strong>Tomada de Preço - Arquivo {i}:</strong>
                                                <a href={dados[arqKey]} target="_blank" rel="noopener noreferrer" className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm">Visualizar arquivo</a>
                                            </p>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                            {dados?.termo && <p><strong>Termo de Homologação/Adjudicação:</strong> {dados.termo}</p>}
                            {dados?.ata_preco && <p><strong>Resumo da Ata de Preço:</strong> {dados.ata_preco}</p>}
                            {dados?.texto && <p><strong>Descrição:</strong> {dados.texto}</p>}
                            {dados?.obs && <p><strong>Obs:</strong> {dados.obs}</p>}
                        </div>
                    ) : (

                        <div className="border border-gray-400 p-4">
                            {dados?.ano && <p><strong>Ano:</strong> {dados.ano}</p>}
                            {dados?.data_pub && <p><strong>Data de Publicação:</strong> {dados.data_pub}</p>}
                            {dados?.codigo1 && <p><strong>Nº do Processo/ Nº do Edital:</strong> {dados.codigo1}</p>}
                            {dados?.codigo2 && <p><strong>Nº da Licitação:</strong> {dados.codigo2}</p>}
                            {dados?.objeto && <p><strong>Objeto:</strong> {dados.objeto}</p>}
                            {dados?.data_abert && <p><strong>Data da Abertura da Licitação:</strong> {dados.data_abert}</p>}
                            {dados?.valor && <p><strong>Valor da Licitação:</strong> {dados.valor}</p>}
                            {dados?.data_hom && <p><strong>Data da Homologação/Adjudicação:</strong> {dados.data_hom}</p>}
                            {dados?.ata && <p><strong>Ata de Abertura e Julgamento:</strong> {dados.ata}</p>}
                            {dados?.arq_data_pub && (
                                <p key="arq_data_pub">
                                    <strong>1. Edital:</strong>
                                    <a href={dados.arq_data_pub} target="_blank" rel="noopener noreferrer" className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm">Visualizar arquivo</a>
                                </p>
                            )}
                            {dados?.proposta_preco && (
                                <p key="proposta_preco">
                                    <strong>1.0 Proposta de Preço:</strong>
                                    <a href={dados.proposta_preco} target="_blank" rel="noopener noreferrer" className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm">Visualizar arquivo</a>
                                </p>
                            )}
                            {dados?.arq_adendo_1 && (
                                <p key="arq_adendo_1">
                                    <strong>1.1. Adendo 1:</strong>
                                    <a href={dados.arq_adendo_1} target="_blank" rel="noopener noreferrer" className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm">Visualizar arquivo</a>
                                </p>
                            )}
                            {dados?.arq_adendo_2 && (
                                <p key="arq_adendo_2">
                                    <strong>1.2. Adendo 2:</strong>
                                    <a href={dados.arq_adendo_2} target="_blank" rel="noopener noreferrer" className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm">Visualizar arquivo</a>
                                </p>
                            )}
                            {dados?.arq_adendo_3 && (
                                <p key="arq_adendo_3">
                                    <strong>1.3. Adendo 3:</strong>
                                    <a href={dados.arq_adendo_3} target="_blank" rel="noopener noreferrer" className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm">Visualizar arquivo</a>
                                </p>
                            )}
                            {dados?.resultado_recurso && (
                                <p key="resultado_recurso">
                                    <strong>Resultado Recurso:</strong>
                                    <a href={dados.resultado_recurso} target="_blank" rel="noopener noreferrer" className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm">Visualizar arquivo</a>
                                </p>
                            )}
                            {dados?.arq_abertura && (
                                <p key="arq_abertura">
                                    <strong>2. Ata de Abertura:</strong>
                                    <a href={dados.arq_abertura} target="_blank" rel="noopener noreferrer" className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm">Visualizar arquivo</a>
                                </p>
                            )}
                            {dados?.arq_registro && (
                                <p key="arq_registro">
                                    <strong>3. Ata de Registro/Contrato:</strong>
                                    <a href={dados.arq_registro} target="_blank" rel="noopener noreferrer" className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm">Visualizar arquivo</a>
                                </p>
                            )}
                            {dados?.arq_adjudicacao && (
                                <p key="arq_adjudicacao">
                                    <strong>4. Ata de Adjudicação/Homologação:</strong>
                                    <a href={dados.arq_adjudicacao} target="_blank" rel="noopener noreferrer" className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm">Visualizar arquivo</a>
                                </p>
                            )}
                            {dados?.arq_programa && (
                                <p key="arq_programa">
                                    <strong>Programa:</strong>
                                    <a href={dados.arq_programa} target="_blank" rel="noopener noreferrer" className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm">Visualizar arquivo</a>
                                </p>
                            )}
                            {dados?.arq_xml && (
                                <p key="arq_xml">
                                    <strong>Programa XML:</strong>
                                    <a href={dados.arq_xml} target="_blank" rel="noopener noreferrer" className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm">Visualizar arquivo</a>
                                </p>
                            )}
                            {dados?.arq_tutorial && (
                                <p key="arq_tutorial">
                                    <strong>Programa Tutorial:</strong>
                                    <a href={dados.arq_tutorial} target="_blank" rel="noopener noreferrer" className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm">Visualizar arquivo</a>
                                </p>
                            )}
                            {dados?.modelo && (
                                <p key="modelo">
                                    <strong>Modelo de Proposta:</strong>
                                    <a href={dados.modelo} target="_blank" rel="noopener noreferrer" className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm">Visualizar arquivo</a>
                                </p>
                            )}
                            {dados?.anexo1 && (
                                <p key="anexo1">
                                    <strong>Anexo 1:</strong>
                                    <a href={dados.anexo1} target="_blank" rel="noopener noreferrer" className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm">Visualizar arquivo</a>
                                </p>
                            )}
                            {dados?.anexo2 && (
                                <p key="anexo2">
                                    <strong>Anexo 2:</strong>
                                    <a href={dados.anexo2} target="_blank" rel="noopener noreferrer" className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm">Visualizar arquivo</a>
                                </p>
                            )}
                            {dados?.adendo1 && (
                                <p key="adendo1">
                                    <strong>Adendo 1:</strong>
                                    <a href={dados.adendo1} target="_blank" rel="noopener noreferrer" className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm">Visualizar arquivo</a>
                                </p>
                            )}
                            {dados?.adendo2 && (
                                <p key="adendo2">
                                    <strong>Adendo 2:</strong>
                                    <a href={dados.adendo2} target="_blank" rel="noopener noreferrer" className="bg-blue-500 text-white py-1 px-2 rounded ml-2 text-sm">Visualizar arquivo</a>
                                </p>
                            )}
                            {dados?.termo && <p><strong>Termo:</strong> {dados.termo}</p>}
                            {dados?.ata_preco && <p><strong>Resumo da Ata de Preço:</strong> {dados.ata_preco}</p>}
                            {dados?.texto && <p><strong>Descrição:</strong> {dados.texto}</p>}
                            {dados?.obs && <p><strong>Obs:</strong> {dados.obs}</p>}
                        </div>

                    )}



                    <div className="mt-4">
                        <Link to="/licitacao" className="bg-blue-500 text-white py-2 px-4 rounded">Voltar</Link>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CLicitacaoDetalhes;
